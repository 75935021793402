<script setup>
const props = defineProps({
  src: {
    type: String,
    default: ''
  },
  format: {
    type: String,
    default: 'jpeg'
  },
  loading: {
    type: String,
    default: 'lazy'
  },
  resize: {
    type: String,
    default: null
  },
  alt: {
    type: String,
    default: 'Stilles'
  },
  title: {
    type: String,
    default: null
  },
  rotate: {
    type: Number,
    default: null
  }
})
const emits = defineEmits(['load'])
const config = useRuntimeConfig().public
const img = ref(null)
const width = ref(0)
const height = ref(0)

onMounted(() => {
  useSize()
  window.addEventListener('resize', useSize)
  window.addEventListener('orientationchange', useSize)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', useSize)
  window.removeEventListener('orientationchange', useSize)
})

const useSize = () => {
  if (img.value !== null) {
    const x = img.value
    const xx = x.getBoundingClientRect()

    x.setAttribute('width', xx.width)
    x.setAttribute('heght', xx.height)
    width.value = xx.width.toFixed()
    height.value = xx.height.toFixed()
  }
}
const useSrc = () => {
  if (+width.value !== 0 && +height.value !== 0) {
    const ext = props.src.split(/[#?]/)[0].split('.').pop().trim()
    const params = {
      format: ext,
      // format: (ext === 'jpeg' || ext === 'jpg') ? 'webp' : ext,
      width: width.value,
      height: height.value,
      src: props.src
    }
    if (props.rotate) params.rotate = props.rotate
    const q = new URLSearchParams(params)
    return config.APP + '/api/image/?' + q.toString()
  }
  else {
    return 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
  }
}
</script>

<template>
  <img
    v-if="src"
    ref="img"
    :src="useSrc()"
    :alt="alt"
    :title="title"
    :loading="loading"
    @load="emits('load')"
  >
</template>
